body {
    background: #e2e1e0;
}

form {
    min-height: 150px;
}

.container {
    background: white;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.logo {
    height: 48px;
}

@media only screen and (min-width: 600px) {
    .logo {
        height: 64px;
    }
}

.email {
    color: black;
}

.email:hover {
    text-decoration: underline;
}